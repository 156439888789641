@import url(./assets/fonts/ALSGorizontVariable/stylesheet.css);
@import url(./assets/fonts/ALSHauss/stylesheet.css);
@import url(./assets/fonts/FSJoey/stylesheet.css);

html[data-theme="light"] {
  --banner-placeholder: #BFBFBF;
  --website-background: #FFFFFF;
  --website-secondary-background: #F3F4F7;
  --website-third-background: #f5f5f5;
  --website-footer-background: #131313;
  --popup-background: rgba(115, 124, 132, 0.2);

  --website-secondary-background-secondary: #EDEFF3;

  --light-grey-border: #DEE4EA;
  --light-grey-status: #767676;

  --neutral-active: #000;
  --neutral-secondary-active: #3D455C;
  --neutral-separetor: #EAECF0;

  --primary-active: #35C650;
  --primary-text: #FFFFFF;
  --primary-hover: #14A32F;
  --primary-disabled: rgba(53, 198, 80, 0.55);
  --primary-text-background-gradient-first: #20F548;
  --primary-text-background-gradient-second: #0AA740;
  --primary-text-background: #4FC865;
  --primary-extra-button: #08E549;

  --footer-button-first: #03FF65;
  --footer-button-second: #08F457;
  --footer-button-third: #07A309;

  --footer-border: #D9D9D9;
  --footer-border-link: #595959;
  --footer-blur-first-color: #70F097;
  --footer-blur-second-color: #68DF85;
  --footer-blur-third-color: #459943;

  --steps-color-accent: #FF5823;
  --steps-template-bg: #FFFFFF;
  /* night mode rgba(255, 255, 255, 0.10) */
  --steps-template-lines: #D4DCE1;
  /* night mode rgba(255, 255, 255, 0.10) */
  --steps-template-light-lines: #EEF2F5;
  /* night mode rgba(255, 255, 255, 0.08) */
  --steps-template-box-bg: #F4F7FA;
  /* night mode rgba(255, 255, 255, 0.08) */

  --pay-example-orange-line: #F35422;

  --partners-first-card-border: #5398F7;
  --partners-second-card-border: #35C650;
  --partners-third-card-border: #ECA435;
  --partners-fours-card-border: #D537FF;
  --partners-first-card-bg: #EBF8FF;
  --partners-second-card-bg: #EBFFEF;
  --partners-third-card-bg: #FFEDCA;
  --partners-fours-card-bg: #FBE5FF;
  --partners-text-color: #272727;
  --partners-form-border: rgba(255, 255, 255, 0.46);
  --partners-form-bg: rgba(115, 124, 132, 0.04);
  --partners-color-accent: #2BBC5D;
  --partners-input-border: #D0D5DD;
  --partners-input-bg: rgba(255, 255, 255, 0.25);
  --partners-input-shadow: rgba(16, 24, 40, 0.05);
  --partners-check-icon: #179B2F;
  --partners-bg-gradient-green-first: #1CD83E;
  --partners-bg-gradient-green-second: #22AF3C;
  --partners-bg-gradient-blue-first: #1770E5;
  --partners-bg-gradient-blue-second: #2762B0;
  --partners-bg-gradient-yellow-first: #FF9E00;
  --partners-bg-gradient-yellow-second: #FF7D00;
  --partners-bg-gradient-red-first: #FE0000;
  --partners-bg-gradient-red-second: #C91010;
  --partners-target-card-color: #000;

  --mobile-first-icons-bg: #FFFFFF;
  --mobile-first-icons-shadow: rgba(0, 0, 0, 0.12);

  --swiper-state-active: #4FC865;
  --swiper-state-hover: #08E549;
  --swiper-state-icon: #CED2DE;

  --global-bg: #050A18;

  --magnati-accent-color: #D81B20;

  --select-dropdown-border-color: #D8D8D8;

  --article-container-color: #F2F2F2;
  --article-border-color: rgba(0, 0, 0, 0.3);
  --article-bad-title-color: #FF1C00;
  --article-circle-first-color: #FFD600;
  --article-circle-secondary-color: #20FFBC;

}



html[data-theme="dark"] {
  --banner-placeholder: #BFBFBF;
  --website-background: #000000;
  /* --website-secondary-background: #1F1F1F; */
  --website-secondary-background: #232323;
  --popup-background: rgba(115, 124, 132, 0.2);

  --website-third-background: #171717;
  --website-footer-background: #0E0E0E;

  --website-secondary-background-secondary: #171717;

  --light-grey-border: #DEE4EA;
  --light-grey-status: #767676;

  --neutral-active: #FFFFFF;
  --neutral-secondary-active: #d6d9e1;
  --neutral-separetor: #171717;

  --primary-active: #35C650;
  --primary-text: #FFFFFF;
  --primary-hover: #14A32F;
  --primary-disabled: rgba(53, 198, 80, 0.55);
  --primary-text-background-gradient-first: #20F548;
  --primary-text-background-gradient-second: #0AA740;
  --primary-text-background: #4FC865;
  --primary-extra-button: #08E549;

  --footer-button-first: #03FF65;
  --footer-button-second: #08F457;
  --footer-button-third: #07A309;

  --footer-border: #D9D9D9;
  --footer-border-link: #595959;
  --footer-blur-first-color: #70F097;
  --footer-blur-second-color: #68DF85;
  --footer-blur-third-color: #459943;

  --steps-color-accent: #FF5823;
  --steps-template-bg: rgba(255, 255, 255, 0.10);

  --steps-template-lines: rgba(255, 255, 255, 0.10);

  --steps-template-light-lines: rgba(255, 255, 255, 0.08);

  --steps-template-box-bg: rgba(255, 255, 255, 0.08);


  --pay-example-orange-line: #F35422;

  --partners-first-card-border: #5398F7;
  --partners-second-card-border: #35C650;
  --partners-third-card-border: #ECA435;
  --partners-fours-card-border: #D537FF;
  --partners-first-card-bg: #EBF8FF;
  --partners-second-card-bg: #EBFFEF;
  --partners-third-card-bg: #FFEDCA;
  --partners-fours-card-bg: #FBE5FF;
  --partners-text-color: #272727;
  --partners-form-border: rgba(255, 255, 255, 0.46);
  --partners-form-bg: rgba(115, 124, 132, 0.04);
  --partners-color-accent: #2BBC5D;
  --partners-input-border: #D0D5DD;
  --partners-input-bg: rgba(255, 255, 255, 0.25);
  --partners-input-shadow: rgba(16, 24, 40, 0.05);
  --partners-check-icon: #179B2F;
  --partners-bg-gradient-green-first: #1CD83E;
  --partners-bg-gradient-green-second: #22AF3C;
  --partners-bg-gradient-blue-first: #1770E5;
  --partners-bg-gradient-blue-second: #2762B0;
  --partners-bg-gradient-yellow-first: #FF9E00;
  --partners-bg-gradient-yellow-second: #FF7D00;
  --partners-bg-gradient-red-first: #FE0000;
  --partners-bg-gradient-red-second: #C91010;
  --partners-target-card-color: #000;

  --mobile-first-icons-bg: #FFFFFF;
  --mobile-first-icons-shadow: rgba(0, 0, 0, 0.12);

  --swiper-state-active: #4FC865;
  --swiper-state-hover: #08E549;
  --swiper-state-icon: #CED2DE;

  --global-bg: #050A18;

  --magnati-accent-color: #D81B20;

  --select-dropdown-border-color: #D8D8D8;

  --article-container-color: rgba(255, 255, 255, 0.3);
  --article-border-color: rgba(255, 255, 255, 0.3);
  --article-bad-title-color: #FF1C00;
  --article-circle-first-color: #FFD600;
  --article-circle-secondary-color: #20FFBC;
}

.swiper-pagination-bullet {
  background-color: var(--neutral-secondary-active) !important;
}

html {

  background-color: var(--website-background);

}

body {
  margin: 0;
  font-family: 'Gorizont', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.2s ease-in-out;
  background-color: var(--website-background);
}

code {
  font-family: 'Gorizont', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-family: 'Gorizont', sans-serif;
  -webkit-box-shadow: 0 0 0 500px var(--website-background) inset !important;
  /* можно изменить на любой вариант цвета */
  -webkit-text-fill-color: var(--neutral-active) !important;
  background-color: var(--website-background) !important;
  background-clip: content-box !important;
  position: relative;
  z-index: 0;
}

p, h1, h2, h3, h4, h5, h6, blockquote {
  padding: 0;
  margin: 0;
  color: var(--neutral-active);
}

p, h1, h2, h3, h4, h5, h6 {
  font-family: 'Gorizont', sans-serif;
}

span {
  padding: 0;
  margin: 0;
  color: inherit;
}

a {
  text-decoration: none;
  color: var(--neutral-active);
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


button {
  font-family: 'Gorizont', sans-serif;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: all 0.2s linear;
  color: var(--neutral-active);
}

/* 
button:hover {
  opacity: 0.8;
}

a{
  transition: all 0.2s linear;
}

a:hover{
  opacity: 0.8;
} */

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bold {
  font-weight: 900 !important;
}

.italic {
  font-style: italic !important;
}

.underline {
  text-decoration: underline;
}

.strikethrough {
  text-decoration: line-through;
}

.goodTitle {
  color: var(--primary-active) !important;
}

.badTitle {
  color: var(--article-bad-title-color) !important;
}

.link {
  text-decoration: underline !important;
  transition: all 0.2s ease-in-out;
}

.link:hover { 
  color: var(--primary-active) !important;
}
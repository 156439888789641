.choose-plan {
    width: 100%;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1920px;
    padding: 37px 0 96px 80px;
}

.choose-plan__title {
    width: 100%;
    text-align: left;
    font-size: 74px;
    font-style: normal;
    font-weight: 750;
    line-height: 120%;
}

.choose-plan__content {
    display: grid;
    width: 100%;
    grid-template-columns: minmax(897px, 1fr) 1fr;
    grid-template-rows: max-content;
    column-gap: 46px;
    margin: 20px 0 0;
    align-items: center;
}

.choose-plan__tarrifs {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minMax(0, 1fr));
    grid-template-rows: max-content;
    column-gap: 20px;
}

.choose-plan__tarrif {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 50px 52px 40px;
    height: 100%;
    border-radius: 20px;
    border: 3px solid var(--website-secondary-background);
}

.choose-plan__tarrif_plus {
    border: 6px solid var(--primary-active);
}

.choose-plan__tarrif-icon {
    width: 180px;
    height: 68px;
}

.choose-plan__tarrif-icon_plus {
    width: 256px;
    height: 74px;
    margin-bottom: -4px;
}

.choose-plan__tarrif-price-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 25px 0 0;
    gap: 15px;
}

.choose-plan__tarrif-price-value {
    font-size: 56px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
}

.choose-plan__tarrif-price-cur-and-period {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: flex-start;

}

.choose-plan__tarrif-price-currency {
    font-size: 40px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
}

.choose-plan__tarrif-price-period {
    font-family: "ALS Hauss";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    opacity: 0.5;
}

.choose-plan__tarrif-start {
    width: 100%;
    border: 3px solid var(--primary-active);
    border-radius: 240px;
    background: var(--primary-active);
    box-sizing: border-box;
    color: var(--primary-text);
    padding: 22px 0;
    text-align: center;
    font-family: "ALS Hauss";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin: 10px 0 0;
}

.choose-plan__tarrif-description {
    margin: 32px 0 0;
    font-family: "ALS Hauss";
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 150%;
}

.choose-plan__tarrif-hint {
    font-family: "ALS Hauss";
    font-size: 15px;
    font-style: normal;
    font-weight: 350;
    line-height: 150%;
    margin: 25px 0 0;
}

.choose-plan__tarrif-key-points {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin: 48px 0 0;
    padding-bottom: 38px;
}

.choose-plan__tarrif-key-point {
    width: 100%;
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-template-rows: max-content;
    align-content: start;
    column-gap: 8px;

}

.choose-plan__tarrif-key-point-tick {
    width: 24px;
    height: 24px;
}

.choose-plan__tarrif-key-point-value {
    font-family: "ALS Hauss";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 133%;
}

.choose-plan__tarrif-key-point-value_final {
    background: linear-gradient(180deg, #65E04D 27.8%, #5CD740 45.8%, #44C11D 79%, #33B004 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: 19.765px;
}

.choose-plan__tarrif-compare {
    text-align: center;
    font-family: "ALS Hauss";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    opacity: 0.5;
    margin: auto 0 0;
}

.choose-plan__tarrif-key-points-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 750;
    line-height: 26px;
}

.choose-plan__img {
    width: 100%;
}

@media (max-width: 1550px) {
    .choose-plan__img {
        display: none;
    }
}

@media (max-width: 1100px) {
    .choose-plan__content {
        grid-template-columns: 1fr;
    }

    .choose-plan {
        padding: 37px 32px 96px;

    }

    .choose-plan__title {
        font-size: 55px;
    }

    .choose-plan__tarrif {
        padding: 40px 34px 30px;
    }
}


@media (max-width: 880px) {
    .choose-plan {
        padding: 10px 20px 60px;
    }

    .choose-plan__title {
        font-size: 36px;
        font-style: normal;
        font-weight: 750;
        line-height: 42px;
        padding: 0 5px;
        box-sizing: border-box;
    }

    .choose-plan__tarrifs {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }

    .choose-plan__tarrif-icon {
        width: 148px;
        height: 56px;
    }

    .choose-plan__tarrif-icon_plus {
        width: 212px;
        height: 60px;
    }

    .choose-plan__tarrif-price-box {
        margin: 20px 0 0;
        gap: 13px;
    }

    .choose-plan__tarrif-price-value {
        font-size: 46px;
        font-style: normal;
        font-weight: 550;
        line-height: normal;
    }

    .choose-plan__tarrif-price-cur-and-period {
        gap: 6px;
    }

    .choose-plan__tarrif-price-currency {
        font-size: 32px;
        font-style: normal;
        font-weight: 550;
        line-height: normal;
    }

    .choose-plan__tarrif-price-period {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 11.529px;
    }

    .choose-plan__tarrif-start {
        margin: 10px 0 0;
        padding: 18px 0;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.765px;
        border-radius: 197.647px;
    }

    .choose-plan__tarrif-description {
        margin: 25px 0 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 19.765px;
    }

    .choose-plan__tarrif-hint {
        font-size: 12px;
        font-style: normal;
        font-weight: 350;
        line-height: 19.765px;
        margin: 20px 0 0;
    }

    .choose-plan__tarrif-key-points {
        margin: 20px 0 0;
        padding-bottom: unset;
    }

    .choose-plan__tarrif-key-point {
        grid-template-columns: 20px 1fr;
        column-gap: 6px;
    }

    .choose-plan__tarrif-key-point-tick {
        width: 20px;
        height: 20px;
    }

    .choose-plan__tarrif-key-point-value {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.765px;
    }

    .choose-plan__tarrif-key-points-title {
        font-size: 16px;
        font-style: normal;
        font-weight: 750;
        line-height: 26px;
    }

    .choose-plan__tarrif-compare {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.761px;
        margin: 42px 0 0;
    }

    .choose-plan__tarrif_plus {
        border: 3px solid var(--primary-active);
    }
}
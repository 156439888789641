.select {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  pointer-events: none;
  cursor: auto;
}

.select__value-box {
  width: 100%;
  height: 84px;
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  align-items: center;
  gap: 12px;
  border-radius: 100px;
  border: 2px solid var(--partners-second-card-border);
  box-shadow: 0px 2px 4px 0px rgba(16, 24, 40, 0.05);
  box-sizing: border-box;
  padding: 0 14px 0 21px;
}

.select__value-icon {
  width: 42px;
  height: 42px;
}

.select__value {
  color: var(--neutral-active);
  opacity: 0.3;
  font-family: "ALS Hauss";
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  line-height: 171%;
}

.select__dropdown {
  width: 108px;
  position: absolute;
  top: 0;
  left: -126px;
  border-radius: 20px;
  border: 2px solid var(--neutral-active);
  background-color: var(--website-background);
  box-shadow: 0px 1px 10px 0px rgba(16, 24, 40, 0.22);
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 10000;
}

.select__dropdown_opened {
  visibility: visible;
  opacity: 1;
}

.select__dropdown-overlay {
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}

.select__dropdown_opened .select__dropdown-overlay {
  width: 100vw;
}

.select__dropdown-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 2px;
  z-index: 101;
}

.select__dropdown-arrow {
  width: 13px;
  height: 23.5px;
  position: absolute;
  top: 33px;
  right: -10px;
}

.select__dropdown-arrow-fill {
  fill: var(--website-background);
}

.select__dropdown-arrow-stroke {
  stroke: var(--neutral-active);
}

.select__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.select__item-btn {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 17px;
  border: 2px solid var(--website-background);
  box-sizing: border-box;
  padding: 8px;
  transition: all 0.2s ease-in-out;
}

.select__item-btn:hover {
  border-color: var(--select-dropdown-border-color);
}

.select__item-btn_selected {
  border-color: var(--primary-active);
  pointer-events: none;
  user-select: none;
}

.select__item-icon {
  width: 26px;
  height: 26px;
}

.select__item-text {
  color: var(--neutral-active);
  opacity: 0.55;
  font-family: "ALS Hauss";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

@media (max-width: 780px) {

  .select__value-box {
    height: 55px;
    gap: 8px;
    border-radius: 50px;
    border: 1px solid var(--partners-second-card-border);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 0 9px 0 12px;
  }

  .select__value-icon {
    width: 27px;
    height: 27px;
  }

  .select__value {
    font-size: 17px;
  }

  .select__dropdown {
    width: 90px;
    top: 64px;
    left: auto;
    border-radius: 16px;
    box-shadow: 0px 1px 8px 0px rgba(16, 24, 40, 0.22);
  }

  .select__dropdown-arrow {
    width: 10px;
    height: 17px;
    transform: rotate(-90deg);
    top: -12px;
    right: auto;
  }

  .select__dropdown-arrow-stroke {
    stroke-width: 4px;
  }

  .select__list {
    gap: 3px;
  }

  .select__item-btn {
    gap: 6px;
    border-radius: 12px;
    border: 1px solid var(--website-background);
    padding: 7px 6px;
  }

  .select__item-btn:hover {
    border-color: var(--select-dropdown-border-color);
  }

  .select__item-btn_selected {
    border-color: var(--primary-active);
  }

  .select__item-icon {
    width: 22px;
    height: 22px;
  }

  .select__item-text {
    font-size: 14px;
  }
}